import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Table, Form } from 'react-bootstrap'
import * as Common from "../../Common";
import Login_header from '../../Login_header';
import Menu from '../../Menu';
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';
import * as Picklist from "../../Picklist";
import $ from "jquery";
import DatePicker from 'react-datepicker';
import Dialog from '../../Dialog';

function Repcommission_status() {
    const sid = sessionStorage.getItem("sessionId");
    const navigate = useNavigate();

    const [userRight, setUserRight] = useState([]);
    const [repLocation, setRepLocation] = useState(null);
    const [repMode, setRepMode] = useState("V");
    const [repLocationValue, setRepLocationValue] = useState("0");
    const [repLocationType, setRepLocationType] = useState("G");
    const [commissionSummary, setCommissionSummary] = useState([]);
    const [frmDate, setFrmDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [paymentCheckbox, setPaymentCheckbox] = useState(true);

    useEffect(() => {
        Common.getActiveBranch().then(response => {
            setRepLocation(response);
            response != null && handleLocationChange(response);
        });
        Common.callApi(Common.apiRight, [sid, 'all', 'REP_COMMISSION_VIEW'], function (result) {
            let resp = JSON.parse(result);
            setUserRight(resp);
            if (resp.query === "0") {
                navigate("/");
            }
        });

    }, []);

    const handleLocationChange = (value) => {
        setRepLocation(value);
        var id = value.id;
        var location = id.split("^");
        setRepLocationValue(location[1]);
        setCommissionSummary([]);
        //setCMStateValue(location[3] == null ? 0 : location[3]);
        //setPPStateType(location[4]);
        //setPPStateName(location[5] === "" ? "Select" : location[5]);
        sessionStorage.activeBranch = location[1];
    }

    const genCommissionSummary = () => {
        $(".loader").show();
        const obj = {
            location: repLocationValue,
            locationtype: repLocationType,
            mode: repMode,
            frmdate: Common.dateYMD(frmDate),
            todate: Common.dateYMD(toDate)
        }
        if (frmDate === null || toDate === null) {
            $(".loader").hide();
            setMsgBox(true);
            setMsgText("Please fill from-to dates.");
        } else {
            if (repMode === "V") {
                Common.callApi(Common.apiCommission, [sid, "repcommissionstatus", JSON.stringify(obj)], result => {
                    $(".loader").hide();
                    console.log(result);
                    setCommissionSummary(JSON.parse(result));
                });
            } else {
                $(".loader").hide();
                Common.callDownloadApiPost(Common.apiCommission, "post", [sid, "repcommissionstatus", JSON.stringify(obj)]);
            }
        }
    }

    const handleLocationTypeChange = (v) => {
        setCommissionSummary([]);
        setRepLocationType(v);
    }


    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3>Commission Status</h3>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>Location Type</Form.Label>
                            <Form.Select value={repLocationType} onChange={e => handleLocationTypeChange(e.target.value)}>
                                <option value="G">Global</option>
                                <option value="S">Selective</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    {
                        repLocationType === "S" &&
                        <Col className='col-md-3 col-6'>
                            <Form.Group>
                                <Form.Label>Location<span className="colorThemeRed">*</span></Form.Label>
                                <AsyncSelect
                                    cacheOptions={false}
                                    defaultOptions={false}
                                    value={repLocation}
                                    getOptionLabel={e => e.label + ' '}
                                    getOptionValue={e => e.id}
                                    loadOptions={Picklist.fetchLocationAllowedPicklist}
                                    onInputChange={Picklist.handleInputChange}
                                    onChange={handleLocationChange}
                                >
                                </AsyncSelect>
                            </Form.Group>
                        </Col>
                    }
                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>Mode</Form.Label>
                            <Form.Select value={repMode} onChange={e => setRepMode(e.target.value)}>
                                <option value="V">View</option>
                                <option value="D">Download</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>From Date</Form.Label>
                            <DatePicker className="form-control"
                                selected={frmDate}
                                onChange={(date) => setFrmDate(date)}
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                                showMonthDropdown
                                useShortMonthInDropdown
                                dropdownMode="select"
                                peekNextMonth
                                customInput={
                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>To Date</Form.Label>
                            <DatePicker className="form-control"
                                selected={toDate}
                                onChange={(date) => setToDate(date)}
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                                showMonthDropdown
                                useShortMonthInDropdown
                                dropdownMode="select"
                                peekNextMonth
                                customInput={
                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                }
                            />
                        </Form.Group>
                    </Col>
                </Row>
                {
                    userRight.query === "1" &&
                    <Row className='mt-2'>
                        <Col className='col-md-4 d-flex align-items-center'>
                            <Button className='buttonStyle' variant='outline-primary' size='sm' onClick={genCommissionSummary}>Generate</Button>
                            &nbsp;&nbsp;
                            <span><Form.Check checked={paymentCheckbox} onChange={e => setPaymentCheckbox(e.target.checked)} type='checkbox' label="View Payment Details" /></span>
                        </Col>
                    </Row>
                }
                <Row>&nbsp;</Row>
                <Row>
                    <Table striped responsive bordered>
                        <thead>
                            <tr>
                                <th>Srno</th>
                                <th>Agent</th>
                                <th>Location</th>
                                <th>Creation Date</th>
                                {/* <th>Tran Date</th> */}
                                <th>Commission</th>
                                <th>Net Margin(Sale)</th>
                                <th>Total GST</th>
                                <th>Total TDS</th>
                                <th>Total Payable</th>
                                <th>Submitted for Approval</th>
                                <th>BM Approved</th>
                                <th>ZM Approved</th>
                                <th>Payment</th>
                                {
                                    paymentCheckbox &&
                                    <>
                                        <th>Reference No</th>
                                        <th>Payment Date</th>
                                        <th>Bank Group</th>
                                        <th>Bank Account</th>
                                    </>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                commissionSummary.map((item) => (
                                    <tr key={item.det_srno + '' + item.det_location}>
                                        <td>{item.det_srno}</td>
                                        <td style={{ backgroundColor: item.tmp_bank_file_downloaded === "1" && "green" }}>{item.det_agent_code + ' ' + item.det_agent_name}</td>
                                        <td>{item.entity_name}</td>
                                        <td>{item.hdr_date}</td>
                                        {/* <td>{item.det_tran_date}</td> */}
                                        <td>{item.det_commission_total}</td>
                                        <td>{item.det_margin_total}</td>
                                        <td>{item.det_cgst * 1 + item.det_igst * 1 + item.det_sgst * 1}</td>
                                        <td>{item.det_tds}</td>
                                        <td>{item.det_commission_net}</td>
                                        <td>{item.det_submit_approval === "1" ? "Yes" : "No"}</td>
                                        <td>{item.det_bm_approved === "1" ? "Yes" : "No"}</td>
                                        <td>{item.det_zm_approved === "1" ? "Yes" : "No"}</td>
                                        <td>{item.tmp_bank_file_downloaded === "1" ? "Yes" : "No"}</td>
                                        {
                                            paymentCheckbox &&
                                            <>
                                                <td>{item.tmp_ref_no}</td>
                                                <td>{item.tmp_value_date}</td>
                                                <td>{item.tmp_from_ftbank_group}</td>
                                                <td>{item.tmp_from_ftbank_account}</td>
                                            </>
                                        }
                                        {sessionStorage.getItem("userSrno") === "1" && <td>{item.det_bmhdr_srno}</td>}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Row>
            </Container>
            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
        </>
    )
}

export default Repcommission_status
