import { faEye } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as Common from '../Common';
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Picklist from '../Picklist';

const Tran_manual_book_cancellation = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const startDate = sessionStorage.getItem('startDate');
    const endDate = sessionStorage.getItem('endDate');

    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const [scrMode, setScrMode] = useState('');

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [isManualBookYN, setManualBookYN] = useState(false);
    const [manualBookTextYN, setManualBookTextYN] = useState("");

    const [mbkLocation, setMbkLocation] = useState(null);
    const [location, setLocation] = useState("");
    const [locationCode, setLocationCode] = useState(0);

    const [radioType, setRadioType] = useState("N");

    const [mbkDate, setMbkDate] = useState(new Date());
    const [mbkFromDate, setMbkFromDate] = useState(new Date());
    const [mbkToDate, setMbkToDate] = useState(new Date());
    const [mbkFromSrno, setMbkFromSrno] = useState('');
    const [mbkToSrno, setMbkToSrno] = useState('');
    const [mbkManualType, setMbkManualType] = useState("PP");
    const [mbkManualNo, setMbkManualNo] = useState(null);
    const [fromManualNo, setFromManualNo] = useState(0);
    const [prefixManualNo, setPrefixManualNo] = useState("");

    const [manualNum, setManualNum] = useState(null);
    const [mbkType, setMbkType] = useState("PP");
    // const [srNo, setSrNo] = useState(0);
    // const [fromManualNum, setFromManualNum] = useState(0);
    // const [prefixManualNum, setPrefixManualNo] = useState("");


    const [innerData, setInnerData] = useState([]);
    const [headerData, setHeaderData] = useState([]);



    var msg = [], i = 0;


    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'MANUAL_CANCELLATION'], (result) => {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleChangeLocation = (value) => {
        setMbkLocation(value);
        const loc = value.id.split('^');
        setLocationCode(loc[1]);
        setLocation(loc[2]);
    }

    const handleManualNoValue = (value) => {
        setMbkManualNo(value);
        console.log(value);
        const manualN = value.id.split('^');
        setPrefixManualNo(manualN[4]);
        setFromManualNo(manualN[5]);
    }

    const addNew = () => {
        if (!mbkLocation || !mbkDate) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (!mbkLocation ? "Please Select Location!" : '');
            msg[i++] = (!mbkDate ? "Please Select Date!" : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            setScrMode("A");
            setInnerData([]);
            setMbkManualNo(null);
            setFromManualNo(0)
            setPrefixManualNo('')
        }
    }

    const viewList = (hdrSrno, hdrLocation, hdrType) => {


        // setSrNo(hdrSrno);
        // setScrMode("Q");
        // const obj = {
        //     srno: hdrSrno,
        //     location: hdrLocation,
        //     type: hdrType,
        // }
        // Common.callApi(Common.apiTRFManualBook, [sid, 'view', JSON.stringify(obj)], (result) => {
        //     let resp = JSON.parse(result);
        //     if (resp.msg === 'MSG0000') {
        //         setInnerData(resp.manualBookList);
        //         setLineNo(resp.maxlineno);
        //         setLocation(resp.locationname);
        //     }
        // });
    }

    const list = () => {
        if (!mbkLocation || (radioType == 'S' && !mbkFromSrno) || (radioType == 'S' && !mbkToSrno) || (radioType == 'D' && !mbkFromDate) || (radioType == 'D' && !mbkToDate) ||
            (radioType == 'MT' && !mbkManualType) || (radioType == 'MN' && !mbkManualNo)) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (!mbkLocation ? "Please Select Location!" : '');
            msg[i++] = ((radioType == 'S' && !mbkFromSrno) ? "Please Enter From Srno!" : '');
            msg[i++] = ((radioType == 'S' && !mbkToSrno) ? "Please Enter To Srno!" : '');
            msg[i++] = ((radioType == 'D' && !mbkFromDate) ? "Please Select From Date!" : '');
            msg[i++] = ((radioType == 'D' && !mbkToDate) ? "Please Select To Date!" : '');
            msg[i++] = ((radioType == 'MT' && !mbkManualType) ? "Please Select Manual Type!" : '');
            msg[i++] = ((radioType == 'MN' && !mbkManualNo) ? "Please Select Manual Number!" : '');

            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        }
        else {
            console.log(mbkLocation);
            const obj = {
                filterRadioType: radioType,
                filterLocation: locationCode,
                filterFromDate: Common.dateYMD(mbkFromDate),
                filterToDate: Common.dateYMD(mbkToDate),
                filterFromSrno: mbkFromSrno,
                filterToSrno: mbkToSrno,
                filterManualType: mbkManualType,
                filterManualNo: mbkManualNo,
                filterFromManualNo: fromManualNo,
                filterPrefixManualNo: prefixManualNo,
            }
            Common.callApi(Common.apiCancelManualBook, [sid, 'list', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setHeaderData(resp);
            });
        }
    }

    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        setScrMode("");
    }

    const editData = () => {
        setScrMode("E");
    }

    const saveData = () => {
        // if (innerData.length < 1) {
        //     setMsgText("Empty List!");
        //     setMsgBox(true);
        // } else {
        //     const obj = {
        //         location: locationCode,
        //         srno: srNo,
        //         date: Common.dateYMD(mbkDate),
        //         data: innerData,

        //         filterRadioType: radioType,
        //         filterLocation: locationCode,
        //         filterFromDate: Common.dateYMD(mbkFromDate),
        //         filterToDate: Common.dateYMD(mbkToDate),
        //         filterFromSrno: mbkFromSrno,
        //         filterToSrno: mbkToSrno,
        //         filterManualType: mbkManualType,
        //         filterManualNo: mbkManualNo,
        //         filterFromManualNo: fromManualNo,
        //         filterPrefixManualNo: prefixManualNo,
        //     }
        //     Common.callApi(Common.apiTRFManualBook, [sid, 'save', JSON.stringify(obj)], (result) => {
        // let resp = JSON.parse(result);
        //         setHeaderData(resp);
        //         setMbkLocation(null);
        //         setInnerData([]);
        //         setScrMode("");
        //     });
        // }
    }

    const handleManualNum = (value) => {
        setManualNum(value);

    }


    const cancelManualBook = () => {
        setManualBookYN(false);

        const obj = {
            location: locationCode,
            manualType: mbkManualType,
            filterRadioType: radioType,
            fromManualNo: fromManualNo,
            prefixManualNo: prefixManualNo,
            date: Common.dateYMD(mbkDate)
        }

        console.log(obj);

        Common.callApi(Common.apiCancelManualBook, [sid, 'cancelManualBook', JSON.stringify(obj)], (result) => {
            console.log(result);
            let resp = JSON.parse(result);
            setHeaderData(resp);
            setScrMode('');
            setMbkManualNo(null);
            setFromManualNo(0)
            setPrefixManualNo('')

        })
    }

    return (
        <div>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h2>Manual Book Cancellation</h2>
                    </Col>
                </Row>

                {
                    scrMode == '' ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={mbkLocation}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleChangeLocation}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col>
                                                <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "MT"} onChange={() => setRadioType("MT")} type='radio' label="Manual Type" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "MN"} onChange={() => setRadioType("MN")} type='radio' label="Manual No" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    radioType === 'N' ?
                                        <>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Date<span className="text-danger">*</span></Form.Label>
                                                    <DatePicker className="form-control"
                                                        selected={mbkDate}
                                                        minDate={new Date(startDate)}
                                                        maxDate={new Date(endDate)}
                                                        onChange={(date) => setMbkDate(date)}
                                                        dateFormat="dd/MM/yyyy"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        useShortMonthInDropdown
                                                        dropdownMode="select"
                                                        peekNextMonth
                                                        customInput={
                                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </>
                                        : radioType === 'S' ?
                                            <>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>From<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={mbkFromSrno}
                                                            maxLength={20}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setMbkFromSrno)}
                                                            placeholder="From" />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>To<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={mbkToSrno}
                                                            maxLength={20}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setMbkToSrno)}
                                                            placeholder="To" />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                            : radioType === 'D' ?
                                                <>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={mbkFromDate}
                                                                onChange={(date) => setMbkFromDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={mbkToDate}
                                                                onChange={(date) => setMbkToDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                                : radioType === 'MT' ?
                                                    <>
                                                        <Col className='col-md-3 col-6'>
                                                            <Form.Group>
                                                                <Form.Label>Manual Type<span className="text-danger">*</span></Form.Label>
                                                                <Form.Select value={mbkManualType} onChange={e => setMbkManualType(e.target.value)}>
                                                                    <option value="PP">PP Purchase Public</option>
                                                                    <option value="SP">SP Sale Public</option>
                                                                    <option value="PB">PB Purchase Bulk</option>
                                                                    <option value="SB">SB Sale Bulk</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                    </>
                                                    : radioType === 'MN' ?
                                                        <>
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>Manual No.<span className='colorThemeRed'>*</span></Form.Label>
                                                                    <AsyncSelect
                                                                        cacheOptions={false}
                                                                        defaultOptions={false}
                                                                        value={mbkManualNo}
                                                                        getOptionLabel={e => e.label + ' '}
                                                                        getOptionValue={e => e.id}
                                                                        loadOptions={(search) => Picklist.fetchManualAllPicklist(search, locationCode)}
                                                                        onInputChange={Picklist.handleInputChange}
                                                                        onChange={handleManualNoValue}
                                                                    >
                                                                    </AsyncSelect>
                                                                </Form.Group>
                                                            </Col>
                                                        </>
                                                        :
                                                        null
                                }
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>

                                    <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => navigate('/cancellation')}>Back</Button>&nbsp;

                                    {
                                        userRight.add === "1" && radioType === 'N' ?
                                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addNew()}>Add New</Button> : null
                                    }
                                    &nbsp;
                                    {
                                        userRight.query === "1" && (radioType === 'S' || radioType === 'D' || radioType === 'MT' || radioType === 'MN') ?
                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => list()}>List</Button>
                                            : null
                                    }
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                {userRight.edit === "1" ? <th></th> : null}
                                                <th>SrNo</th>
                                                <th>Date</th>
                                                <th>Type</th>
                                                <th>Manual No.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                headerData.map((res) =>
                                                    <tr key={res.hdr_srno}>
                                                        {userRight.edit === "1" ?
                                                            <td className="textCenter">
                                                                <span className="handCursor colorThemeBlue" onClick={() => viewList(res.hdr_srno, res.hdr_location, res.hdr_type)}>
                                                                    <FontAwesomeIcon icon={faEye} />
                                                                </span>
                                                            </td>
                                                            : null
                                                        }
                                                        <td>{res.tmbb_srno}</td>
                                                        <td>{res.formatted_date}</td>
                                                        <td>{res.tmbb_manual_type}</td>
                                                        <td>{res.tmbb_prefix} {res.tmbb_from}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </>
                        : <>
                            <Row>
                                <Col>
                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={() => cancelConfirm()}>Back</Button>{' '}
                                    {
                                        (userRight.edit === "1" && scrMode === "Q") ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                editData()}>Edit</Button>
                                        </> : null
                                    }
                                    {/* {
                                        (userRight.edit === "1" && scrMode === "E") || (userRight.add === "1" && scrMode === "A") ? <>
                                            <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => 
                                            { setManualBookTextYN('Do you really want to cancel this manual book entry ?');
                                                setManualBookYN(true);
                                                }}>Cancel Manual Book</Button>
                                        </> : null
                                    } */}
                                    {/* <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFile}>Export</Button> */}
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                {/* <Col className='col-md-3 col-6'>
                                    <Form.Label>Sr No : {srNo}</Form.Label>
                                </Col> */}
                                <Col className='col-md-3 col-6'>
                                    <Form.Label>Location : {location}</Form.Label>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>

                            {
                                scrMode === "E" || scrMode === "A" &&
                                <>
                                    <Row>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Type<span className="text-danger">*</span></Form.Label>
                                                <Form.Select value={mbkManualType} onChange={e => setMbkManualType(e.target.value)}>
                                                    <option value="PP">PP Purchase Public</option>
                                                    <option value="SP">SP Sale Public</option>
                                                    <option value="PB">PB Purchase Bulk</option>
                                                    <option value="SB">SB Sale Bulk</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Manual No.<span className="text-danger">*</span></Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={mbkManualNo}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchManualTypePicklist(search, locationCode, '')}
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={handleManualNoValue}
                                                >
                                                </AsyncSelect>
                                            </Form.Group>

                                        </Col>
                                    </Row>&nbsp;

                                    <Row>
                                        <Col>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => {
                                                setManualBookTextYN('Do you really want to cancel this manual book entry ?');
                                                setManualBookYN(true);
                                            }}>Cancel Manual Book</Button>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </>
                }
            </Container>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>

                <DialogYesNo isOpen={isManualBookYN} onYes={(e) => cancelManualBook()} onNo={(e) => setManualBookYN(false)}>
                    {manualBookTextYN}
                </DialogYesNo>

            </div>
        </div>
    )
}

export default Tran_manual_book_cancellation
