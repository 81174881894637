import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Tab, Table, Tabs } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";
import * as Common from "../../Common";
import Login_header from '../../Login_header';
import Menu from '../../Menu';
import * as Picklist from "../../Picklist";

import { faEye } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from "jquery";
import ActivityLog from '../../ActivityLog';
import Dialog from '../../Dialog';
import DialogYesNo from '../../Dialog_Yes_No';
import Master_ledger_commission from './Master_ledger_commission';
import Master_ledger_documents from './Master_ledger_documents';
import Master_ledger_location from './Master_ledger_location';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

function Master_voucher_ledger() {
    const taxClassOPtions = [{ value: 0, label: "Select" },
    { value: 1, label: "Gst" },
    { value: 2, label: "Unregistered" },
    { value: 3, label: "Gst Excluded" }];
    const sid = sessionStorage.getItem("sessionId");
    const [onceRun, setOnceRun] = useState(false);
    const [userRight, setUserRight] = useState([]);
    const navigate = useNavigate();
    const [visiblePg, setVisiblePg] = useState("");

    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [ldgSrno, setLdgSrno] = useState("");
    const [ldgName, setLdgName] = useState("");
    const [grpCode, setGrpCode] = useState("");
    const [ldgAddress, setLdgAddress] = useState("");
    const [ldgPinCode, setLdgPinCode] = useState("");
    const [ldgRbiLicense, setLdgRbiLicense] = useState("");
    const [ldgLastReviewDate, setLdgLastReviewDate] = useState(new Date());
    const [ldgWebsite, setLdgWebsite] = useState("");
    const [ldgValidity, setLdgValidity] = useState(new Date());
    const [ldgScope, setLdgScope] = useState("");
    const [ldgStatus, setLdgStatus] = useState("1");

    /*########### Taxation ##################*/
    const [ldgGst, setLdgGst] = useState("");
    const [ldgARN, setLdgARN] = useState("");
    const [ldgTds, setLdgTds] = useState("");
    const [ldgPan, setLdgPan] = useState("");
    const [ldgAadhar, setLdgAadhar] = useState("");
    const [ldgUIN, setLdgUIN] = useState("");
    const [ldgServiceCharge, setLdgServiceCharge] = useState("1");
    const [ldgGstin, setLdgGstin] = useState("");
    const [ldgGstExemptionDate, setLdgGstExemptionDate] = useState(new Date());


    /*########### Risk ##################*/
    const [ldgCreditAllow, setLdgCreditAllow] = useState(0);
    const [ldgCreditLimit, setldgCreditLimit] = useState("");
    const [ldgCreditPeriod, setldgCreditPeriod] = useState("");
    const [ldgExpectedVolLow, setLdgExpectedVolLow] = useState("");
    const [ldgExpectedVolMedium, setLdgExpectedVolMedium] = useState("");
    const [ldgExpectedVolHigh, setLdgExpectedVolHigh] = useState("");

    /*########### Additional ##################*/
    const [ldgPhone, setLdgPhone] = useState("");
    const [ldgMobile, setLdgMobile] = useState("");
    const [ldgEmail, setLdgEmail] = useState("");
    const [ldgListedIn, setLdgListedIn] = useState("");
    const [ldgBeneOwner, setLdgBeneOwner] = useState("");
    const [ldgBeneMobile, setLdgBeneMobile] = useState("");
    const [ldgBankAccNum, setLdgBankAccNum] = useState("");
    const [ldgBankName, setLdgBankName] = useState("");
    const [ldgFundSource, setLdgFundSource] = useState("");
    const [ldgPaymentMode, setLdgPaymentMode] = useState("");
    const [ldgSocialStatus, setLdgSocialStatus] = useState("");


    /*########### Options ##################*/
    const [ldgCountry, setLdgCountry] = useState({ value: 0, label: "Select" });
    const [ldgState, setLdgState] = useState({ value: 0, label: "Select" });
    const [ldgDistrict, setLdgDistrict] = useState({ value: 0, label: "Select" });
    const [ldgCity, setLdgCity] = useState({ value: 0, label: "Select" });
    const [ldgDealer, setLdgDealer] = useState({ value: 0, label: "Select" });
    const [ldgGroup, setLdgGroup] = useState({ value: 0, label: "Select" });
    const [ldgTaxclassOptions, setLdgTaxclassOptions] = useState(taxClassOPtions);
    const [ldgMSMEOptions, setLdgMSMEOptions] = useState([{ value: 0, label: "Select" }, { value: 1, label: "Dummy" }]);
    const [ldgRiskOptions, setLdgRiskOptions] = useState([]);
    const [ldgLegalOptions, setLdgLegalOptions] = useState([{ value: 0, label: "Select" }, { value: 1, label: "Dummy" }]);
    const [ldgNatureStatusOptions, setLdgNatureStatusOptions] = useState([]);
    const [ldgAmtManagerOptions, setLdgAmtManagerOptions] = useState([{ value: 0, label: "Select" }, { value: 1, label: "Dummy" }]);

    const [ldgCountryOptions, setLdgCountryOptions] = useState([]);
    const [ldgStateOptions, setLdgStateOptions] = useState([]);
    const [ldgDistrictOptions, setLdgDistrictOptions] = useState([]);
    const [ldgCityOptions, setLdgCityOptions] = useState([]);
    const [ldgDealerOptions, setLdgDealerOptions] = useState([]);
    const [ldgGroupOptions, setLdgGroupOptions] = useState([]);
    const [ldgTaxclass, setLdgTaxclass] = useState({ value: 0, label: "Select" });
    const [ldgMSME, setLdgMSME] = useState({ value: 0, label: "Select" });
    const [ldgRisk, setLdgRisk] = useState({ value: 0, label: "Select" });
    const [ldgLegalStatus, setLdgLegalStatus] = useState({ value: 0, label: "Select" });
    const [ldgNatureStatus, setLdgNatureStatus] = useState({ value: 0, label: "Select" });
    const [ldgAmtManager, setLdgAmtManager] = useState({ value: 0, label: "Select" });

    const [ledgerList, setLedgerList] = useState([]);
    const [disableGroupOptions, setDisableGroupOptions] = useState(false);

    const [groupTypeOptions, setGroupTypeOptions] = useState([]);
    const [groupType, setGroupType] = useState('');
    const [listType, setListType] = useState({ value: "ALL", label: "All" });

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");

    const [ledgerDocument, setLedgerDocument] = useState([]);
    const [commissionList, setCommissionList] = useState([]);
    const [locationLink, setLocationLink] = useState([]);
    const [bankAccNo, setBankAccNo] = useState("");
    const [bankIfsc, setBankIfsc] = useState("");
    const [ldgBankOptions, setLdgBankOptions] = useState([])
    const [ldgBank, setLdgBank] = useState({ value: 0, label: "Select" });
    const [fxGroup, setFxGroup] = useState('');
    const [fxAccount, setFxAccount] = useState('')

    const [tranAuditTrail, setTranAuditTrail] = useState([]);
    const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });
    const [showActivityLog, setShowActivityLog] = useState(false);

    //WALKIN CREDIT LIMIT
    const [crdLmtBranch, setCrdLmtBranch] = useState();
    const [crdLmtAmount, setCrdLmtAmount] = useState();
    const [crdLmtSrno, setCrdLmtSrno] = useState(0);
    const [crdLmtDays, setCrdLmtDays] = useState();
    const [walkinCreditLimitList, setWalkinCreditLimitList] = useState([]);
    const [walkinCreditLimitListlength, setWalkinCreditLimitListlength] = useState(0);



    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'VOUCHERLEDGER'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                    Common.callApi(Common.apiLedger, [sid, "getoptions"], result => {
                        let resp = JSON.parse(result);
                        //setLdgGroupOptions(resp.group);
                        setLdgDealerOptions(resp.dealer);
                        setLdgCountryOptions(resp.country);
                        setLdgNatureStatusOptions(resp.businessnature);
                        setLdgRiskOptions(resp.risk);
                        setLdgLegalOptions(resp.legal);
                        setLdgMSMEOptions(resp.msme);
                    });
                    Common.callApi(Common.apiLedger, [sid, "distinctgrouptype"], result => {
                        setGroupTypeOptions(JSON.parse(result));
                    });
                } else {
                    navigate('/'); // no query right
                }
            });
            setOnceRun(true);
        }
    }, [onceRun]);

    const getLedgerList = () => {
        $(".loader").show();
        const obj = {
            type: listType.value
        }
        Common.callApi(Common.apiLedger, [sid, "getlist", JSON.stringify(obj)], result => {
            $(".loader").hide();
            setLedgerList(JSON.parse(result));
        });
    }

    const handleLdgGroupValue = (value) => {
        setLdgGroup(value);
        const grpcd = value.id.split('^');
        setGrpCode(grpcd[1]);
    }

    const getGroupVal = () => {
        Common.callApi(Common.apiLedger, [sid, "getgroupval", listType.value], result => {
            let resp = JSON.parse(result);
            setLdgGroupOptions(resp);
            if (resp.length > 1) {
                setDisableGroupOptions(false);
                setLdgGroup({ id: '^0^SELECT^0^SELECT^', label: 'Select' });
                setGrpCode(0);
            } else {
                setLdgGroup(resp);
                setDisableGroupOptions(true);
                setGrpCode(resp[0].id.split('^')[1]);
            }

            /*
                Common.callApi(Common.apiLedger, [sid, "getgroupval", listType.value], result => {
                    let resp = JSON.parse(result);
                    console.log(resp);
                    const grpcd = resp.value.split('^');
                    setGrpCode(grpcd[1]);
                });
            */
            // {id: '^1500^RESERVE AND SURPLUS^1500^OTHER^', label: '1500 RESERVE AND SURPLUS 1500 OTHER'}

        });
    }

    const addNewForm = () => {
        if (listType.value == 'ALL') {
            setMsgBox(true);
            setMsgText("Please select list type.");
        } else {
            //if (listType.value == 'AGENT' || listType.value == 'DEALER' || listType.value == 'CORPORATE') {
            setVisiblePg("A");
            getGroupVal();

        }
        setLdgSrno(0);
        setLdgAddress("");
        setLdgName("");
        setLdgLastReviewDate(new Date());
        setLdgValidity(new Date());
        setLdgPinCode("");
        setLdgRbiLicense("");
        setLdgWebsite("");
        setLdgScope("");
        setLdgStatus("1");

        setLdgPan("");
        setLdgTds("");
        setLdgGst("");
        setLdgGstin("");
        setLdgGstExemptionDate(new Date());
        setLdgARN("");
        setLdgUIN("");
        setLdgAadhar("");
        setLdgMSME({ value: 0, label: "Select" });
        setLdgServiceCharge("1");

        setLdgCreditAllow(0);
        setldgCreditLimit("");
        setldgCreditPeriod("");
        setLdgRisk({ value: 0, label: "Select" });
        setLdgLegalStatus({ value: 0, label: "Select" });
        setLdgNatureStatus({ value: 0, label: "Select" });
        setLdgExpectedVolHigh("");
        setLdgExpectedVolMedium("");
        setLdgExpectedVolLow("");

        setLdgPhone("");
        setLdgMobile("");
        setLdgEmail("");
        setLdgListedIn("");
        setLdgBeneOwner("");
        setLdgBeneMobile("");
        setLdgBankName("");
        setLdgBankAccNum("");
        setLdgFundSource("");
        setLdgPaymentMode("");
        setLdgSocialStatus("");

        setLdgDistrict({ value: 0, label: "Select" });
        setLdgCountry({ value: 0, label: "Select" });
        setLdgCity({ value: 0, label: "Select" });
        setLdgState({ value: 0, label: "Select" });
        setLdgDealer({ value: 0, label: "Select" });
        setLdgTaxclass({ value: 0, label: "Select" });
        setLdgAmtManager({ value: 0, label: "Select" });
        setLdgBank({ value: 0, label: "Select" });
        setFxAccount("");
        setFxGroup('')
        setBankAccNo('')
        setBankIfsc('')
        if (listType.value == 'BANK') {
            console.log("bank")
            getBankOption();
        }
        if (listType.value == 'WALKIN') {
            setWalkinCreditLimitListlength(0);
            setWalkinCreditLimitList([]);
        }
    }

    const saveData = () => {
        $(".loader").show();
        var msg = [], i = 0;
        if (listType.value === "AGENT" || listType.value === "CORPORATE" || listType.value === "DEALER") {
            if (ldgName === "" || ldgGroup.value == 0
                || (listType.value === "DEALER" && (ldgDealer.value == 0 || ldgRbiLicense == "")) || ldgLastReviewDate == ""
                || ldgValidity == "" || ldgScope === "" || ldgTaxclass.value == 0 || ldgPan === ""
                || ldgAddress === "" || ldgCountry.value == 0 || ldgState.value == 0 || ldgDistrict.value == 0 || ldgPinCode == ""
                || (listType.value === "CORPORATE" && (ldgMSME.value == 0 || ldgLegalStatus.value == 0 || ldgNatureStatus.value == 0))
                || (ldgTaxclass.value === 'GST' && (ldgGstin === ""))
                || (ldgTaxclass.value === 'GSTEXEMPTED' && (ldgGstExemptionDate === ""))
                || (ldgCreditAllow == 1 && (ldgCreditLimit == "" || ldgCreditPeriod == ""))
                || ldgRisk.value == 0
            ) {
                msg[i++] = Common.getMessage("MSG0006");
                msg[i++] = (ldgGroup.id == '^0^SELECT^0^SELECT^' ? " Group." : '');
                msg[i++] = (ldgName === "" ? " Ledger name." : '');
                msg[i++] = (ldgAddress === "" ? "Address." : '');
                msg[i++] = (ldgCountry.value == 0 ? "Country." : '');
                if (ldgCountry.value == 1) {
                    msg[i++] = (ldgState.value == 0 ? "State." : '');
                    msg[i++] = (ldgDistrict.value == 0 ? "District." : '');
                }
                msg[i++] = (ldgPinCode === "" ? "Pincode." : '');
                msg[i++] = (ldgLastReviewDate === "" ? " Last review date." : '');
                msg[i++] = (ldgValidity === "" ? " Validity." : '');
                msg[i++] = (ldgScope === "" ? " Scope." : '');
                msg[i++] = (ldgTaxclass.value == "0" ? " Tax class." : '');
                msg[i++] = (ldgPan === "" ? " Pancard." : '');
                msg[i++] = (ldgRisk.value == 0 ? " Risk." : '');
                if (listType.value === 'DEALER') {
                    msg[i++] = (ldgDealer.value == 0 ? " Dealer Type." : '');
                    msg[i++] = (ldgRbiLicense === "" ? " RBI License." : '');
                } else if (listType.value === "CORPORATE") {
                    msg[i++] = (ldgMSME.value == "0" ? " MSME." : '');
                    msg[i++] = (ldgLegalStatus.value == "0" ? " Legal Status." : '');
                    msg[i++] = (ldgNatureStatus.value == "0" ? " Nature of business." : '');
                }
                if (ldgTaxclass.value == 1) {
                    msg[i++] = (ldgGstin === "" ? " GSTIN." : '');
                }
                if (ldgGst === "0") {
                    msg[i++] = (ldgGstExemptionDate === "" ? " GST Exemption date." : '');
                }
                if (ldgCreditAllow == 1) {
                    msg[i++] = (ldgCreditLimit == "" ? " Credit limit." : '');
                    msg[i++] = (ldgCreditPeriod == "" ? " Credit Period." : '');
                }
                $(".loader").hide();
                setMsgBox(true);
                setMsgText(Common.buildMessageFromArray(msg));
            }
        } else {
            if (listType.value === "WALKIN" && (ldgName === "" || ldgScope === "")) {
                msg[i++] = Common.getMessage("MSG0006");
                msg[i++] = (ldgName === "" ? " Ledger name." : '');
                msg[i++] = (ldgScope === "" ? " Scope." : '');
                $(".loader").hide();
                setMsgBox(true);
                setMsgText(Common.buildMessageFromArray(msg));
            } else if (listType.value === "BANK" && (bankAccNo == "" || bankIfsc == "" || ldgName === "" || ldgScope === "")) {
                msg[i++] = Common.getMessage("MSG0006");
                msg[i++] = (ldgName === "" ? " Ledger name." : '');
                msg[i++] = (bankAccNo == "" ? " Account Number." : '');
                msg[i++] = (bankIfsc == "" ? " Bank IFSC Code." : '');
                msg[i++] = (ldgScope === "" ? " Scope." : '');
                $(".loader").hide();
                setMsgBox(true);
                setMsgText(Common.buildMessageFromArray(msg));
            } else if (ldgName === "" || ldgGroup.id == '^0^SELECT^0^SELECT^' || ldgLastReviewDate === "" || ldgValidity === "" || ldgScope === "") {
                msg[i++] = Common.getMessage("MSG0006");
                msg[i++] = (ldgGroup.id == '^0^SELECT^0^SELECT^' ? " Group." : '');
                msg[i++] = (ldgName === "" ? " Ledger name." : '');
                msg[i++] = (ldgLastReviewDate === "" ? " Last review date." : '');
                msg[i++] = (ldgValidity === "" ? " Validity." : '');
                msg[i++] = (ldgScope === "" ? " Scope." : '');
                $(".loader").hide();
                setMsgBox(true);
                setMsgText(Common.buildMessageFromArray(msg));
            }
        }
        //to check gst number and pan number matching or not
        if (ldgPan && ldgGstin) {
            if (!ldgGstin.includes(ldgPan)) {
                msg[i++] = 'GSTIN is not matching with PAN Number ';
                $(".loader").hide();
                setMsgBox(true);
                setMsgText(Common.buildMessageFromArray(msg));
            }
        }

        if (msg.length == 0) {
            const obj = {
                ldgName: ldgName,
                ldgAddress: ldgAddress,
                ldgSrno: ldgSrno,
                ldgCity: ldgCity.value,
                ldgCountry: ldgCountry.value,
                ldgState: ldgState.value,
                ldgDistrict: ldgDistrict.value,
                ldgDealer: ldgDealer.value,
                ldgGroup: grpCode,
                ldgPinCode: ldgPinCode,
                ldgValidity: Common.dateYMD(ldgValidity),
                ldgLastReviewDate: Common.dateYMD(ldgLastReviewDate),
                ldgWebsite: ldgWebsite,
                ldgStatus: ldgStatus,
                ldgScope: ldgScope,
                ldgRbiLicense: ldgRbiLicense,

                // taxation 

                ldgPan: ldgPan,
                ldgTaxclass: ldgTaxclass.value,
                ldgServiceCharge: ldgServiceCharge,
                ldgTds: ldgTds,
                ldgGst: ldgGst,
                ldgGstin: ldgGstin,
                ldgGstExemptionDate: Common.dateYMD(ldgGstExemptionDate),
                ldgARN: ldgARN,
                ldgUIN: ldgUIN,
                ldgAadhar: ldgAadhar,
                ldgMSME: ldgMSME.value,

                //RISK

                ldgCreditAllow: ldgCreditAllow,
                ldgCreditLimit: ldgCreditLimit,
                ldgCreditPeriod: ldgCreditPeriod,
                ldgRisk: ldgRisk.value,
                ldgLegalStatus: ldgLegalStatus.value,
                ldgNatureStatus: ldgNatureStatus.value,
                ldgExpectedVolLow: ldgExpectedVolLow,
                ldgExpectedVolMedium: ldgExpectedVolMedium,
                ldgExpectedVolHigh: ldgExpectedVolHigh,

                //additional

                ldgPhone: ldgPhone,
                ldgMobile: ldgMobile,
                ldgEmail: ldgEmail,
                ldgListedIn: ldgListedIn,
                ldgBeneOwner: ldgBeneOwner,
                ldgBeneMobile: ldgBeneMobile,
                ldgAmtManager: ldgAmtManager.value,
                ldgBankName: ldgBankName,
                ldgBankAccNum: listType.value === "BANK" ? bankAccNo : ldgBankAccNum,
                ldgFundSource: ldgFundSource,
                ldgPaymentMode: ldgPaymentMode,
                ldgSocialStatus: ldgSocialStatus,
                bankIfsc: bankIfsc,
                fxGroup: fxGroup ? fxGroup : 0,
                fxAccount: fxAccount ? fxAccount : 0,
                bankCode: ldgBank ? ldgBank : { value: "", label: "Select" },

                TabType: "Product",
                ALineNo: tranAuditTrail.length + 1,
                AType: "A",
                TType: 'MVLGD',
            }
            //if listtype is walkin than walkincredit limitlist
            if (listType.value === "WALKIN") {
                obj.walkinCreditLimitList = walkinCreditLimitList;
            }
            // var tran_audit_trail1 = Common.arrayAddItem(tran_audit_trail, walkinCreditLimitList);
            // console.log('hi', ldgTaxclass.value);
            // console.log("this is what u want", obj);
            // const obj2 = {
            //     LedgerName: ldgName,
            //     LedgerAdderess: ldgAddress,
            //     LedgerCity: ldgCity.value,
            //     LedgerCity: ldgCountry.value,
            //     LedgerCity: ldgState.value,
            //     LedgerDistrict: ldgDistrict.value,
            //     LedgerDealer: ldgDealer.value,
            //     LedgerGroup: grpCode,
            //     LedgerPinCode: ldgPinCode,
            //     LedgerWebsite: ldgWebsite,
            //     LedgerStatus: ldgStatus,
            //     LedgerScope: ldgScope,
            //     LedgerRBILisence: ldgRbiLicense,

            //     // taxation 

            //     ldgPan: ldgPan,
            //     ldgTaxclass: ldgTaxclass.value,
            //     ldgServiceCharge: ldgServiceCharge,
            //     ldgTds: ldgTds,
            //     ldgGst: ldgGst,
            //     ldgGstin: ldgGstin,
            //     ldgGstExemptionDate: Common.dateYMD(ldgGstExemptionDate),
            //     ldgARN: ldgARN,
            //     ldgUIN: ldgUIN,
            //     ldgAadhar: ldgAadhar,
            //     ldgMSME: ldgMSME.value,

            //     //RISK

            //     ldgCreditAllow: ldgCreditAllow,
            //     ldgCreditLimit: ldgCreditLimit,
            //     ldgCreditPeriod: ldgCreditPeriod,
            //     ldgRisk: ldgRisk.value,
            //     ldgLegalStatus: ldgLegalStatus.value,
            //     ldgNatureStatus: ldgNatureStatus.value,
            //     ldgExpectedVolLow: ldgExpectedVolLow,
            //     ldgExpectedVolMedium: ldgExpectedVolMedium,
            //     ldgExpectedVolHigh: ldgExpectedVolHigh,

            //     //additional

            //     ldgPhone: ldgPhone,
            //     ldgMobile: ldgMobile,
            //     ldgEmail: ldgEmail,
            //     ldgListedIn: ldgListedIn,
            //     ldgBeneOwner: ldgBeneOwner,
            //     ldgBeneMobile: ldgBeneMobile,
            //     ldgAmtManager: ldgAmtManager.value,
            //     ldgBankName: ldgBankName,
            //     ldgBankAccNum: listType.value === "BANK" ? bankAccNo : ldgBankAccNum,
            //     ldgFundSource: ldgFundSource,
            //     ldgPaymentMode: ldgPaymentMode,
            //     ldgSocialStatus: ldgSocialStatus,
            //     bankIfsc: bankIfsc,
            //     fxGroup: fxGroup ? fxGroup : 0,
            //     fxAccount: fxAccount ? fxAccount : 0,
            //     bankCode: ldgBank ? ldgBank : { value: "", label: "Select" },
            // }
            var tran_audit_trail = Common.arrayAddItem(tranAuditTrail, obj);
            setTranAuditTrail(tran_audit_trail);
            Common.callApi(Common.apiLedger, [sid, "savedata", JSON.stringify(obj), JSON.stringify(tran_audit_trail)], result => {
                $(".loader").hide();
                let resp = JSON.parse(result);
                if (resp.msg === "MSG0000") {
                    setVisiblePg("");
                    getLedgerList();
                    setTranAuditTrail([]);
                    setWalkinCreditLimitList([]);
                    setWalkinCreditLimitListlength(0);
                } else {
                    setMsgBox(true);
                    $(".loader").hide();
                    setMsgText(Common.getMessage(resp.err));
                }
            });
        }
    }

    const handleCountryChange = (v) => {
        setLdgCountry(v);
        Common.callApi(Common.apiLedger, [sid, "getstate", v.value], result => {
            setLdgStateOptions(JSON.parse(result));
        });
    }

    const handleBankChange = (v) => {
        console.log('hello')
        setLdgBank(v);
    }

    const getBankOption = async () => {
        console.log("bank option")
        await Common.callApi(Common.apiLedger, [sid, "getBank"], result => {
            setLdgBankOptions(JSON.parse(result));
        });
    }

    const handleStateChange = (v) => {
        setLdgState(v);
        Common.callApi(Common.apiLedger, [sid, "getcity", v.value], result => {
            setLdgCityOptions(JSON.parse(result));
        });
        Common.callApi(Common.apiDistrict, [sid, "getdistrict", v.value], result => {
            setLdgDistrictOptions(JSON.parse(result));
        });
    }

    const fillEditForm = (srno, groupsrno) => {
        setLdgSrno(srno);
        setVisiblePg("Q");
        setDisableGroupOptions(true);
        Common.callApi(Common.apiLedger, [sid, "details", srno, groupsrno], result => {
            let resp = JSON.parse(result);
            if (resp.msg === "MSG0000") {
                setLdgName(resp.name);
                setLdgAddress(resp.address);
                setLdgCountry({ value: resp.country, label: resp.cntname });
                setLdgCity({ value: resp.city, label: resp.cityname });
                setLdgDistrict({ value: resp.district, label: resp.distname });
                setLdgState({ value: resp.state, label: resp.statename });
                setLdgDealer({ value: resp.dealer, label: resp.dealername });
                setLdgRbiLicense(resp.rbilic);
                setLdgValidity(new Date(resp.validity));
                setLdgLastReviewDate(new Date(resp.lastreview));
                setLdgWebsite(resp.website);
                setLdgScope(resp.scope);
                setLdgStatus(resp.status);
                setLdgServiceCharge(resp.servicecharge);
                setLdgCreditAllow(resp.creditallowed);
                setldgCreditLimit(resp.creditlimit);
                setldgCreditPeriod(resp.creditperiod);
                setLdgExpectedVolLow(resp.expvolumelow);
                setLdgExpectedVolMedium(resp.expvolumemed);
                setLdgExpectedVolHigh(resp.expvolumehigh);
                setLdgPhone(resp.phone);
                setLdgMobile(resp.mobile);
                setLdgEmail(resp.email);
                setLdgListedIn(resp.listedin);
                setLdgBeneOwner(resp.beneowner);
                setLdgBeneMobile(resp.benemobile);
                setLdgAmtManager({ value: resp.amtmanager, label: "Select" });
                setLdgGroup({ value: resp.group, label: resp.group + " " + resp.groupname });
                setLdgBankName(resp.bankname);
                setLdgBankAccNum(resp.accountnum);
                setLdgFundSource(resp.fundsource);
                setLdgPaymentMode(resp.paymentmode);
                setLdgSocialStatus(resp.socialstatus);
                setGrpCode(resp.group);
                setListType({ value: resp.grouptype, label: resp.grouptype });
                setLdgMSME({ value: resp.msme, label: resp.msmedesc });
                setLdgPan(resp.pancard);
                setLdgTds(resp.tds);
                setLdgTaxclass(taxClassOPtions.filter(val => val.value == resp.taxclass)[0]);
                setLdgGst(resp.gst);
                setLdgGstin(resp.gstin);
                if (resp.exemptiondate !== "") {
                    setLdgGstExemptionDate(new Date(resp.exemptiondate));
                }
                setLdgARN(resp.arn);
                setLdgUIN(resp.uin);
                setLdgAadhar(resp.aadhar);
                setLdgServiceCharge(resp.servicecharge);
                setLdgPinCode(resp.pin);
                setLdgLegalStatus({ value: resp.legalstatus, label: resp.legalstatusdesc });
                setLdgNatureStatus({ value: resp.businessnature, label: resp.businessnaturedesc });
                setLdgRisk({ value: resp.risk, label: resp.riskdesc });
                setOnceRun(false);
                setBankAccNo(resp.accountnum);
                setBankIfsc(resp.bankIfsc)
                setFxAccount(resp.fxAccount)
                setFxGroup(resp.fxGroup);

                //Ledger Document
                setLedgerDocument(resp.ledgerdocument);
                setCommissionList(resp.commissiondetail);
                setLocationLink(resp.locationlist);
                console.log('details', resp.locationlist)
                // getGroupVal();
                if (listType.value == 'BANK') {
                    console.log("bank")
                    getBankOption();
                    setLdgBank({ value: resp.bankCode, label: resp.bankName })
                }
                if (listType.value == 'WALKIN') {
                    const walkinList = resp.walkincreditlist;
                    setWalkinCreditLimitListlength(walkinList.length);
                    setWalkinCreditLimitList(walkinList.map((data, index) => {
                        return {
                            srno: data.srno,
                            branch: { id: '^' + data.branch.split(' ').join('^') + '^', label: data.branch },
                            creditdays: data.creditdays,
                            creditamount: data.creditamount,
                            creditdate: new Date(data.date),
                            tempsrno: index + 1,
                            isActive: 1
                        }
                    }))
                }
            }
        });
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        setVisiblePg("");
    }

    const cancelConfirm = () => {
        if (visiblePg === "A" || visiblePg === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const editFields = () => {
        setVisiblePg("E");
    }

    const exportFile = () => {
        var obj = {
            right: 'VOUCHERLEDGER',
            type: listType.value,
        }
        Common.callDownloadApiPost(Common.apiReport, "post", [sid, JSON.stringify(obj)]);
    }
    const btnActivityLog = () => {
        setShowActivityLog(true);
        setLogObject({ trantype: "MVLGD", trannumber: ldgSrno, trancategory: "M" });
    }

    //WALKIN CREDIT LIMIT 
    function walkinCreditLimitListHandler() {
        let i = 0, msg = [];
        msg[i++] = Common.getMessage("MSG0006");//i=1
        !crdLmtBranch && (msg[i++] = " Branch.");
        !crdLmtAmount && (msg[i++] = " Credit Amount.");
        !crdLmtDays && (msg[i++] = " Credit Days.");

        if (i > 1) {
            setMsgBox(true);
            setMsgText(Common.buildMessageFromArray(msg));
        } else {
            //srno 0 means this is a new entry
            setWalkinCreditLimitList([...walkinCreditLimitList, { srno: 0, tempsrno: walkinCreditLimitListlength + 1, branch: crdLmtBranch, creditamount: crdLmtAmount, creditdays: crdLmtDays, creditdate: new Date(), isActive: 1 }]);
            setWalkinCreditLimitListlength(prevValue => prevValue + 1);
            setCrdLmtAmount(0);
            setCrdLmtBranch({ id: '', label: '' });
            setCrdLmtDays(0);
        }
    }

    function deleteWalkinCreditItem(tempsrno) {
        const newArrayList = walkinCreditLimitList.map((data) => {
            if (data.tempsrno === tempsrno) {//data we want to delete 
                if (data.srno != 0) {
                    return { ...data, isActive: 0 }
                } else {
                    return;
                }
            } else if (data.tempsrno > tempsrno) {// update other tempsrno of above data 
                return { ...data, tempsrno: data.tempsrno - 1 }
            }
            return data;
        }).filter(Boolean);
        console.log(newArrayList);
        setWalkinCreditLimitList(newArrayList);
        setWalkinCreditLimitListlength((prevValue) => prevValue - 1);

    }


    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h1>Voucher Ledger</h1>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                {
                    visiblePg === "" ?
                        <>
                            <Row>
                                <Col xs={3} >
                                    <Form.Group>

                                        <Select options={groupTypeOptions} value={listType} onChange={v => setListType(v)} />
                                    </Form.Group>
                                </Col>
                                <Col xs={9} >
                                    <Button className='buttonStyle' size='sm' variant='outline-primary' onClick={() => getLedgerList()}>List</Button>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    {
                                        userRight.add === "1" ?
                                            <>
                                                <Button className='buttonStyle' size='sm' variant='outline-primary' onClick={() => addNewForm()}>Add New</Button>&nbsp;
                                                <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={() => exportFile()}>Export</Button>
                                            </>
                                            : null
                                    }
                                    &nbsp;
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table responsive striped bordered>
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th>Srno</th>
                                                <th>Ledger Name</th>
                                                <th>Group Code</th>
                                                <th>Group Name</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                ledgerList.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <span className='colorThemeBlue' onClick={() => fillEditForm(item.mvli_srno, item.mvli_group)}>
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </span>
                                                        </td>
                                                        <td>{item.mvli_srno}</td>
                                                        <td>{item.mvli_ledger_name}</td>
                                                        <td>{item.mvli_group}</td>
                                                        <td>{item.mvg_group_name}</td>
                                                        {/* <td>{item.state_name}</td> */}
                                                        <td>{item.mvli_status == 1 ? "ACTIVE" : "INACTIVE"}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Row>
                                <Col>
                                    <Button size='sm' className='buttonStyle' variant='outline-primary' onClick={() => cancelConfirm()}>Back</Button>
                                    &nbsp;
                                    {
                                        (userRight.edit === "1" && visiblePg === 'E') || (userRight.add === "1" && visiblePg === 'A') ?
                                            <Button size='sm' className='buttonStyle' variant='outline-primary' onClick={() => saveData()}>Save</Button>
                                            : null
                                    }
                                    &nbsp;
                                    {(userRight.edit === "1") ? <>
                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                            btnActivityLog()}>Activity Log</Button>&nbsp;
                                    </> : null
                                    }
                                    &nbsp;
                                    {
                                        (userRight.edit === "1" && visiblePg === 'Q') ?
                                            <Button size='sm' className='buttonStyle' variant='danger' onClick={() => editFields()}>Edit</Button>
                                            : null
                                    }

                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Tabs>
                                <Tab eventKey="INFO" title="Info">
                                    <Row>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>SrNo</Form.Label>
                                                <Form.Control disabled={true} value={ldgSrno} onChange={e => setLdgSrno(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            {/* <Form.Group>
                                                <Form.Label>Group</Form.Label>
                                                <Select isDisabled={disableGroupOptions} options={ldgGroupOptions} value={ldgGroup} onChange={v => setLdgGroup(v)} />
                                            </Form.Group> */}
                                            <Form.Group>
                                                <Form.Label>Group<span className="text-danger">*</span></Form.Label>
                                                <AsyncSelect
                                                    isDisabled={disableGroupOptions}
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={ldgGroup}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchGroupPicklist(search, (listType.value))}
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={handleLdgGroupValue}
                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Ledger Name<span className="text-danger">*</span></Form.Label>
                                                <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='Name' maxLength="100" value={ldgName}
                                                    onChange={(e) => Common.validateAlpNumValue(e.target.value, setLdgName)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {listType.value == 'BANK' &&
                                        <Row>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Bank</Form.Label>
                                                    <Select isDisabled={visiblePg === 'Q'} options={ldgBankOptions} value={ldgBank} onChange={v => handleBankChange(v)} />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Account Number<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='Account Number' maxLength="20" value={bankAccNo}
                                                        onChange={(e) => setBankAccNo(e.target.value)}
                                                        onBlur={(e) => Common.validateNumValue(e.target.value, setBankAccNo)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>IFSC Code<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='IFSC Code' value={bankIfsc}
                                                        maxLength={11}
                                                        onBlur={(e) => Common.validateIFSC(e.target.value, setBankIfsc)}
                                                        onChange={(e) => setBankIfsc(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col></Col>
                                            <Col></Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Address{listType.value === "CORPORATE" || listType.value === "DEALER" || listType.value === "AGENT" ? <span className="text-danger">*</span> : null}</Form.Label>
                                                <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='Address' maxLength="200" value={ldgAddress} onChange={e => setLdgAddress(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Country{listType.value === "CORPORATE" || listType.value === "DEALER" || listType.value === "AGENT" ? <span className="text-danger">*</span> : null}</Form.Label>
                                                <Select isDisabled={visiblePg === 'Q'} options={ldgCountryOptions} value={ldgCountry} onChange={v => handleCountryChange(v)} />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>State{(ldgCountry.value == 1) && (listType.value === "CORPORATE" || listType.value === "DEALER" || listType.value === "AGENT") ? <span className="text-danger">*</span> : null}</Form.Label>
                                                <Select isDisabled={visiblePg === 'Q'} options={ldgStateOptions} value={ldgState} onChange={v => handleStateChange(v)} />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>District{(ldgCountry.value == 1) && (listType.value === "CORPORATE" || listType.value === "DEALER" || listType.value === "AGENT") ? <span className="text-danger">*</span> : null}</Form.Label>
                                                <Select isDisabled={visiblePg === 'Q'} options={ldgDistrictOptions} value={ldgDistrict}
                                                    onChange={v => setLdgDistrict(v)} />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>City</Form.Label>
                                                <Select isDisabled={visiblePg === 'Q'} options={ldgCityOptions} value={ldgCity} onChange={v => setLdgCity(v)} />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Pin Code{listType.value === "CORPORATE" || listType.value === "DEALER" || listType.value === "AGENT" ? <span className="text-danger">*</span> : null}</Form.Label>
                                                <Form.Control disabled={visiblePg === 'Q'} placeholder='Pin Code' type='text' maxLength={6} value={ldgPinCode} onChange={e => Common.validateNumValue(e.target.value, setLdgPinCode)} />
                                            </Form.Group>
                                        </Col>
                                        {
                                            listType.value === "DEALER" ?
                                                <>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Dealer Type<span className="text-danger">*</span></Form.Label>
                                                            <Select isDisabled={visiblePg === 'Q'} options={ldgDealerOptions} value={ldgDealer} onChange={v => setLdgDealer(v)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>RBI License<span className="text-danger">*</span></Form.Label>
                                                            <Form.Control disabled={visiblePg === 'Q'} type='text' maxLength={100} value={ldgRbiLicense} onChange={e => setLdgRbiLicense(e.target.value)} />
                                                        </Form.Group>
                                                    </Col>
                                                </> : null
                                        }
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Last Review<span className="text-danger">*</span></Form.Label>
                                                <DatePicker className="form-control"
                                                    selected={ldgLastReviewDate}
                                                    onChange={(date) => setLdgLastReviewDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    useShortMonthInDropdown
                                                    dropdownMode="select"
                                                    peekNextMonth
                                                    disabled={visiblePg === 'Q'}
                                                    customInput={
                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Website</Form.Label>
                                                <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='Website' maxLength="100" value={ldgWebsite} onChange={e => setLdgWebsite(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Validity<span className="text-danger">*</span></Form.Label>
                                                <DatePicker className="form-control"
                                                    selected={ldgValidity}
                                                    onChange={(date) => setLdgValidity(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    useShortMonthInDropdown
                                                    dropdownMode="select"
                                                    peekNextMonth
                                                    disabled={visiblePg === 'Q'}
                                                    customInput={
                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Scope<span className="text-danger">*</span></Form.Label>
                                                <Form.Select value={ldgScope} disabled={visiblePg === 'Q'} onChange={e => setLdgScope(e.target.value)}>
                                                    <option value="">Select</option>
                                                    <option value="G">Global</option>
                                                    <option value="S">Selective</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Status</Form.Label>
                                                <Form.Select value={ldgStatus} disabled={visiblePg === 'Q'} onChange={e => setLdgStatus(e.target.value)}>
                                                    <option value="1">Active</option>
                                                    <option value="0">Inactive</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Fx Group</Form.Label>
                                                <Form.Control disabled={visiblePg === 'Q'} type='number' placeholder='Fx Group Number' maxLength="20" value={fxGroup} onChange={e => setFxGroup(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Fx Account</Form.Label>
                                                <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='Fx Account Number' maxLength="20" value={fxAccount} onChange={e => setFxAccount(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab>
                                {
                                    listType.value === "DEALER" || listType.value === "CORPORATE" || listType.value === "AGENT" ?
                                        <Tab eventKey="TAXATION" title="Taxation">
                                            <Row>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Tax Class<span className="text-danger">*</span></Form.Label>
                                                        <Select isDisabled={visiblePg === 'Q'} options={ldgTaxclassOptions} value={ldgTaxclass} onChange={v => setLdgTaxclass(v)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Service Charge</Form.Label>
                                                        <Form.Select disabled={visiblePg === 'Q'} value={ldgServiceCharge} onChange={e => setLdgServiceCharge(e.target.value)}>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>PAN Card<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control disabled={visiblePg === 'Q'} type='text' maxLength="10" placeholder='Pan' value={ldgPan}
                                                            onChange={e => setLdgPan(e.target.value)}
                                                            onBlur={(e) => Common.validatePan(e.target.value, setLdgPan)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    {listType.value !== "CORPORATE" &&
                                                        <Form.Group>
                                                            <Form.Label>TDS(%)</Form.Label>
                                                            <Form.Control disabled={visiblePg === 'Q'} type="text" value={ldgTds} onChange={e => Common.validateDecValue(e.target.value, setLdgTds)} />
                                                        </Form.Group>
                                                    }
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>GST</Form.Label>
                                                        <Form.Select disabled={visiblePg === 'Q'} value={ldgGst} onChange={e => setLdgGst(e.target.value)}>
                                                            <option value="">Select</option>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>GSTIN{ldgTaxclass.value == 1 ? <span className="text-danger">*</span> : null}</Form.Label>
                                                        <Form.Control disabled={visiblePg === 'Q'} type='text' maxLength="15" placeholder='GstIn' value={ldgGstin}
                                                            onChange={e => setLdgGstin(e.target.value)}
                                                            onBlur={(e) => Common.validateGST(e.target.value, setLdgGstin)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Gst Exemption Till{ldgGst === "0" ? <span className="text-danger">*</span> : null}</Form.Label>
                                                        <DatePicker className="form-control"
                                                            selected={ldgGstExemptionDate}
                                                            onChange={(date) => setLdgGstExemptionDate(date)}
                                                            dateFormat="dd/MM/yyyy"
                                                            showYearDropdown
                                                            showMonthDropdown
                                                            useShortMonthInDropdown
                                                            dropdownMode="select"
                                                            peekNextMonth
                                                            disabled={visiblePg === 'Q'}
                                                            customInput={
                                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>ARN</Form.Label>
                                                        <Form.Control disabled={visiblePg === 'Q'} type='text' maxLength="23" placeholder='ARN' value={ldgARN} onChange={e => setLdgARN(e.target.value)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>UIN</Form.Label>
                                                        <Form.Control disabled={visiblePg === 'Q'} type='text' maxLength="15" placeholder='UIN' value={ldgUIN} onChange={e => setLdgUIN(e.target.value)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Aadhar Card</Form.Label>
                                                        <Form.Control disabled={visiblePg === 'Q'} type='text' maxLength="12" placeholder='Aadhar Num' value={ldgAadhar} onChange={e => Common.validateNumValue(e.target.value, setLdgAadhar)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>MSME{listType.value === "CORPORATE" ? <span className="text-danger">*</span> : null}</Form.Label>
                                                        <Select isDisabled={visiblePg === 'Q'} options={ldgMSMEOptions} value={ldgMSME} onChange={v => setLdgMSME(v)} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Tab>
                                        : null
                                }
                                {
                                    listType.value === "DEALER" || listType.value === "CORPORATE" || listType.value === "AGENT" ?
                                        <Tab eventKey="RISK" title="Risk">
                                            <Row>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Credited Allowed</Form.Label>
                                                        <Form.Select disabled={visiblePg === 'Q'} value={ldgCreditAllow} onChange={e => setLdgCreditAllow(e.target.value)}>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                {
                                                    ldgCreditAllow === "1" ?
                                                        <>
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>Credit Limit</Form.Label>
                                                                    <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='Limit' maxLength="10" value={ldgCreditLimit} onChange={e => Common.validateNumValue(e.target.value, setldgCreditLimit)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>Credit Period</Form.Label>
                                                                    <Form.Control disabled={visiblePg === 'Q'} placeholder='Credit Period' type='text' maxLength="10" value={ldgCreditPeriod} onChange={e => Common.validateNumValue(e.target.value, setldgCreditPeriod)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </> : null
                                                }
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Risk<span className="text-danger">*</span></Form.Label>
                                                        <Select isDisabled={visiblePg === 'Q'} options={ldgRiskOptions} value={ldgRisk} onChange={v => setLdgRisk(v)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Expected volume low</Form.Label>
                                                        <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='Volume low' value={ldgExpectedVolLow} onChange={e => Common.validateDecValue(e.target.value, setLdgExpectedVolLow)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Expected volume medium</Form.Label>
                                                        <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='Volume medium' value={ldgExpectedVolMedium} onChange={e => Common.validateDecValue(e.target.value, setLdgExpectedVolMedium)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Expected volume high</Form.Label>
                                                        <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='Volume high' value={ldgExpectedVolHigh} onChange={e => Common.validateDecValue(e.target.value, setLdgExpectedVolHigh)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Legal Status{listType.value === "CORPORATE" ? <span className="text-danger">*</span> : null}</Form.Label>
                                                        <Select isDisabled={visiblePg === 'Q'} options={ldgLegalOptions} value={ldgLegalStatus} onChange={v => setLdgLegalStatus(v)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Nature of Business{listType.value === "CORPORATE" ? <span className="text-danger">*</span> : null}</Form.Label>
                                                        <Select isDisabled={visiblePg === 'Q'} options={ldgNatureStatusOptions} value={ldgNatureStatus} onChange={v => setLdgNatureStatus(v)} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Tab>
                                        : null
                                }
                                {
                                    listType.value === "DEALER" || listType.value === "CORPORATE" || listType.value === "AGENT" ?
                                        <Tab eventKey="ADDITIONAL" title="Additional">
                                            <Row>
                                                {/*########################  Addition Details ####################### */}
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Phone</Form.Label>
                                                        <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='Phone' maxLength={10} value={ldgPhone} onChange={e => Common.validateDecValue(e.target.value, setLdgPhone)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Mobile</Form.Label>
                                                        <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='Mobile' maxLength={10} value={ldgMobile} onChange={e => Common.validateDecValue(e.target.value, setLdgMobile)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control
                                                            disabled={visiblePg === 'Q'}
                                                            type='text'
                                                            placeholder='Email'
                                                            maxLength={100}
                                                            value={ldgEmail}
                                                            onChange={(e) => setLdgEmail(e.target.value.trim())}
                                                            onBlur={(e) => Common.validtateEmail(e.target.value.trim(), setLdgEmail)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Listed In</Form.Label>
                                                        <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='Listed In' maxLength={100} value={ldgListedIn} onChange={e => Common.validateAlpValue(e.target.value, setLdgListedIn)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Beneficiary Owner</Form.Label>
                                                        <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='Bene Owner' maxLength={100} value={ldgBeneOwner} onChange={e => Common.validateAlpValue(e.target.value, setLdgBeneOwner)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Beneficiary Mobile</Form.Label>
                                                        <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='Bene Mobile' maxLength={10} value={ldgBeneMobile} onChange={e => Common.validateNumValue(e.target.value, setLdgBeneMobile)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Amount Manager</Form.Label>
                                                        <Select isDisabled={visiblePg === 'Q'} options={ldgAmtManagerOptions} value={ldgAmtManager} onChange={v => setLdgAmtManager(v)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Bank Name</Form.Label>
                                                        <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='Bank Name' maxLength={100} value={ldgBankName} onChange={e => Common.validateAlpValue(e.target.value, setLdgBankName)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Bank Account Number</Form.Label>
                                                        <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='Bank acc num' maxLength={100} value={ldgBankAccNum} onChange={e => Common.validateNumValue(e.target.value, setLdgBankAccNum)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Source of Fund</Form.Label>
                                                        <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='Source of fund' maxLength={100} value={ldgFundSource} onChange={e => Common.validateAlpValue(e.target.value, setLdgFundSource)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Payment Mode</Form.Label>
                                                        <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='Payment Mode' maxLength={50} value={ldgPaymentMode} onChange={e => Common.validateAlpValue(e.target.value, setLdgPaymentMode)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Social Status</Form.Label>
                                                        <Form.Control disabled={visiblePg === 'Q'} type='text' placeholder='Social Status' maxLength={100} value={ldgSocialStatus} onChange={e => Common.validateAlpValue(e.target.value, setLdgSocialStatus)} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Tab>
                                        : null
                                }
                                {/* {
                                    listType.value === "DEALER" || listType.value === "CORPORATE" || listType.value === "AGENT" ? 
                                     : null
                                } */}

                                {
                                    ldgScope === 'S' ?
                                        <Tab eventKey="LOCATION" title="Location">
                                            <Master_ledger_location ldggrpsrno={ldgGroup.value} ldgsrno={ldgSrno} visiblePg={visiblePg} locationLink={locationLink} />
                                        </Tab> : null
                                }
                                {
                                    listType.value === "AGENT" ?
                                        <Tab eventKey="COMMISSION" title="Commission">
                                            <Master_ledger_commission deleteRight={userRight.delete} ldggrpsrno={ldgGroup.value} ldgsrno={ldgSrno} visiblePg={visiblePg} commissionList={commissionList} />
                                        </Tab> : null
                                }
                                {
                                    ldgSrno > 0 ?
                                        <Tab eventKey="DOCUMENTS" title="Documents">
                                            <Master_ledger_documents ldggrpsrno={ldgGroup.value} ldgsrno={ldgSrno} visiblePg={visiblePg} listType={listType.value} ledgerDocument={ledgerDocument} />
                                        </Tab>
                                        : null
                                }
                                {
                                    listType.value === "WALKIN" ?
                                        <Tab eventKey="CREDITLIMIT" title="Credit Limit">
                                            <>
                                                {
                                                    visiblePg != 'Q' &&
                                                    <>
                                                        <Row>
                                                            <Col className='col-md-4'>
                                                                <Form.Group>
                                                                    <Form.Label>Branch</Form.Label>
                                                                    <AsyncSelect
                                                                        cacheOptions={false}
                                                                        defaultOptions={false}
                                                                        value={crdLmtBranch}
                                                                        getOptionLabel={e => e.label + ' '}
                                                                        getOptionValue={e => e.id}
                                                                        loadOptions={Picklist.fetchLocationAllowedPicklist}
                                                                        onInputChange={Picklist.handleInputChange}
                                                                        onChange={setCrdLmtBranch}
                                                                    >
                                                                    </AsyncSelect>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className='col-md-4 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>Credit Amount</Form.Label>
                                                                    <Form.Control
                                                                        value={crdLmtAmount}
                                                                        maxLength={Common.maximumLength}
                                                                        onChange={(e) => Common.validateNumValue(e.target.value, setCrdLmtAmount)}
                                                                        placeholder="Credit Amount"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className='col-md-4 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>Credit Days</Form.Label>
                                                                    <Form.Control
                                                                        value={crdLmtDays}
                                                                        maxLength={3}
                                                                        onChange={(e) => Common.validateNumValue(e.target.value, setCrdLmtDays)}
                                                                        placeholder="Credit Days"
                                                                    // disabled={scrMode === 'V' || (viewStatus !== 'P' && scrMode === 'E') || (srno != 0 && requesterName.split(' ')[0] !== userSrno)}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        &nbsp;
                                                        <Row>
                                                            <Col>
                                                                <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={walkinCreditLimitListHandler}>Add</Button>
                                                            </Col>
                                                        </Row>
                                                        &nbsp;
                                                    </>
                                                }
                                                <Row>
                                                    <Col>
                                                        <Table responsive striped bordered>
                                                            <thead>
                                                                <tr>
                                                                    <th>Srno</th>
                                                                    <th>Branch</th>
                                                                    <th>Credit Amount</th>
                                                                    <th>Credit  Days</th>
                                                                    <th>Created Date</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    walkinCreditLimitList?.map((item, index) => (
                                                                        (item?.isActive !== 0 ?
                                                                            <tr key={index}>
                                                                                <td className="textCenter">
                                                                                    <span className="handCursor colorThemeBlue" onClick={() => deleteWalkinCreditItem(item.tempsrno)}>
                                                                                        {visiblePg != 'Q' && <FontAwesomeIcon icon={faTrash} />}
                                                                                    </span>{' '} {item.tempsrno}
                                                                                </td>
                                                                                <td>{item.branch.label}</td>
                                                                                <td>{item.creditamount}</td>
                                                                                <td>{item.creditdays}</td>
                                                                                <td>{Common.dateDMY(item.creditdate) + ' ' + item.creditdate.toTimeString().split(' ')[0]}</td>
                                                                            </tr> : null
                                                                        )
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row>
                                            </>
                                        </Tab> : null
                                }
                            </Tabs>
                            <Row>&nbsp;</Row>
                        </>
                }
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
            </Container>
            <ActivityLog logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
            </div>
            <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                {msgTextYN}
            </DialogYesNo>
        </>
    )
}

export default Master_voucher_ledger
