import { faEye } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import ActivityLog from '../ActivityLog';
import * as Common from "../Common";
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import FaView from '../FaView';
import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Picklist from '../Picklist';

const Issue_Cheque_Bounce = () => {

  const navigate = useNavigate();
  const sid = sessionStorage.getItem('sessionId');
  const finStartDate = sessionStorage.getItem("startDate");
  const finEndDate = sessionStorage.getItem("endDate");
  const branchDisable = sessionStorage.getItem('branchDisable');
  const [onceRun, setOnceRun] = useState(false);
  const [userRight, setUserRight] = useState([]);
  const user = sessionStorage.getItem('userSrno');
  const [scrMode, setScrMode] = useState('');
  const [radioType, setRadioType] = useState("N");
  const [ledgerValue, setLedgerValue] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [isMsgBox, setMsgBox] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isMsgBoxYN, setMsgBoxYN] = useState(false);
  const [msgTextYN, setMsgTextYN] = useState("");
  const [filterDate, setFilterDate] = useState(new Date());
  const [srnoFrom, setSrnoFrom] = useState("");
  const [srnoTo, setSrnoTo] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [acRemark, setAcRemark] = useState("");
  const [code, setCode] = useState(0);
  const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });
  const [showActivityLog, setShowActivityLog] = useState(false);
  const [srNo, setSrNo] = useState("");
  const [randomString, setRandomString] = useState("");
  const [showHeaderList, setShowHeaderList] = useState(false);
  const [showList, setShowList] = useState(false);
  const [bookList, setBookList] = useState([]);
  const [amount, setAmount] = useState("");
  const [bankGroup, setBankGroup] = useState("")
  const [bankAccount, setBankAccount] = useState("")
  const [viewBank, setViewBank] = useState("");
  const [viewDate, setViewDate] = useState("");
  const [chequeList, setChequeList] = useState(null);
  const [partyValue, setPartyValue] = useState(null)
  const [ldgSrchType, setLdgSrchType] = useState("");
  const [location, setLocation] = useState("");
  const [locationCode, setLocationCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [group, setGroup] = useState("");
  const [account, setAccount] = useState("");
  const [accountType, setAccountType] = useState("");
  const [chequeNum, setChequeNum] = useState("");
  const [mainSrNo, setMainSrNo] = useState('0');
  const [lineNum, setLineNum] = useState("");
  const [maintype, setMainType] = useState("");
  const [chequeStatus, setChequeStatus] = useState(null);
  const [chequeDate, setChequeDate] = useState("");
  const [chequeBank, setChequeBank] = useState("");
  const [chequeLocation, setChequeLocation] = useState("");
  const [chequeGroup, setChequeGroup] = useState("");
  const [chequeAccount, setChequeAccount] = useState("");
  const [headerList, setHeaderList] = useState([]);
  const [viewSrno, setViewSrno] = useState("");
  const [faViewObject, setFaViewObject] = useState({ location: "", trannumber: "", type: "" });
  const [showFaView, setShowFaView] = useState(false);

  useEffect(() => {
    if (onceRun) {
      return;
    } else {
      Common.getActiveBranch().then(response => {
        setSelectedValue(response);
        response != null && handleChange(response);
      });
      Common.callApi(Common.apiRight, [sid, 'all', 'ISSUE_CHEQUE_BOUNCE'], function (result) {
        let resp = JSON.parse(result);
        if (resp.query === "1") {
          setUserRight(resp);
        } else {
          navigate('/');
        }
      });
      setOnceRun(true);
    }
  }, [onceRun, sid, navigate]);

  const handleChange = value => {
    setSelectedValue(value);
    const loc = value.id.split('^');
    setLocationCode(loc[1]);
    setLocation(loc[2]);
    sessionStorage.activeBranch = loc[1];
  }

  const handleLedgerValue = (value) => {
    setLedgerValue(value);
    const account = value.id.split('^');
    console.log(account);
    setBankGroup(account[1]);
    setBankAccount(account[2]);
    const bank = account[3];
    setBankName(bank);
    setViewBank(bank);
  }

  const handleAddNew = () => {
    var msg = [], i = 0;
    var d = Common.dateYMD(filterDate);
    if (selectedValue === null || ledgerValue === null || !filterDate) {
      msg[i++] = Common.getMessage("MSG0006");
      msg[i++] = (selectedValue === null ? " Location." : '');
      msg[i++] = (ledgerValue === null ? " Bank." : '');
      msg[i++] = (filterDate === null ? " Date." : '');
      setMsgText(Common.buildMessageFromArray(msg));
      setMsgBox(true);
    } else if (d > finEndDate || d < finStartDate) {
      setMsgText(Common.getMessage("MSG0010"));
      setMsgBox(true);
    } else {
      setScrMode('A');
      setSrNo(0);
      setRandomString(user + "_CHKDP_" + Common.getRandomString(15));
      setShowHeaderList(false);
      setShowList(true);
      setBookList([]);
      setViewBank(bankName);
      setViewDate(Common.dateDMY(filterDate));
    }
    setLdgSrchType('AGENT,CORPORATE,DEALER,OTHER,ISSUER,WALKIN');
    setCode(0);
  }

  const handleShowList = () => {
    var msg = [], i = 0;
    if (selectedValue === null || ledgerValue === null || (radioType === 'S' && !srnoFrom) || (radioType === 'S' && !srnoTo) || (radioType === 'D' && !fromDate)
      || (radioType === 'D' && !toDate)) {
      msg[i++] = Common.getMessage("MSG0006");
      msg[i++] = (selectedValue === null ? " Location." : '');
      msg[i++] = (ledgerValue === null ? " Bank." : '');
      msg[i++] = (radioType === 'S' && !srnoFrom ? " From Srno." : '');
      msg[i++] = (radioType === 'S' && !srnoTo ? " To Srno." : '');
      msg[i++] = ((radioType === 'D' && !fromDate) ? " From Date." : '');
      msg[i++] = ((radioType === 'D' && !toDate) ? " To Date." : '');
      setMsgText(Common.buildMessageFromArray(msg));
      setMsgBox(true);
    } else {
      const obj = {
        date: Common.dateYMD(filterDate),
        type: 'ISSUECHQ',
        location: locationCode,
        radioType: radioType,
        fromSrno: srnoFrom,
        toSrno: srnoTo,
        fromDate: Common.dateYMD(fromDate),
        toDate: Common.dateYMD(toDate)
      }
      Common.callApi(Common.apiIssueChequeBounce, [sid, 'showIssueChequeBounceHeader', JSON.stringify(obj)], (result) => {
        let resp = JSON.parse(result);
        setHeaderList(resp);
        setShowHeaderList(true);
      });
    }
  }

  const handleBack = () => {
    setMsgBoxYN(true);
    setMsgTextYN("Do you really want to exit");
  }

  const cancelData = () => {
    if (scrMode == 'A' || scrMode == 'Q') {
      setScrMode('');
      setShowHeaderList(true);
      handleShowList();
    } else {
      setScrMode('Q');
    }
    setMsgBoxYN(false);
    setLedgerValue(null);
  }

  const handleView = (vsrno, vtype, vdate) => {
    $(".loader").show();
    setSrNo(vsrno);
    const obj = {
      srno: vsrno,
      location: locationCode,
      scrMode: scrMode,
      type: vtype,
    }
    Common.callApi(Common.apiIssueChequeBounce, [sid, 'showIssueChequeBounce', JSON.stringify(obj)], (result) => {
      let resp = JSON.parse(result);
      console.log(resp);
      $(".loader").hide();
      setBookList(resp);
      setScrMode('Q');
      setShowList(true);
      setViewSrno(vsrno);
      setViewDate(vdate);
    });
  }

  const handleSaveData = () => {
    var msg = [], i = 0;
    if (chequeList === null || partyValue === null) {
      $(".loader").hide();
      msg[i++] = Common.getMessage("MSG0006");
      msg[i++] = (partyValue === null ? " Party." : '');
      msg[i++] = (chequeList === null ? " Cheque." : '');
      setMsgText(Common.buildMessageFromArray(msg));
      setMsgBox(true);
    } else if (scrMode === 'E' && acRemark === '') {
      setShowModal(true);
      $(".loader").hide();
    } else {
      const obj = {
        remark: acRemark,
        activitytype: code == 1 ? 'MODIFY' : 'CREATE',
        subtranno: code,
        trannumber: srNo,
        trantype: 'ISSUECHQ',
        trancategory: 'T',
        branch: locationCode,

        uniquekey: Common.getRandomString(15),//
        date: Common.dateYMD(filterDate),//
        amount: amount,//
        location: locationCode,//
        srno: srNo,
        cheque_status: chequeStatus,//
        cheque_no: chequeNum,//
        chequeDate: chequeDate,//
        cheque_bank_name: chequeBank,//
        line_no: lineNum,
        tfd_type: maintype,
        tfd_srno: mainSrNo,
        radioType: radioType,
        type: 'ISSUECHQ',
        partyGroup: group,
        partyAccount: account,
        ref_location: chequeLocation,
        chequeGroup: chequeGroup,
        chequeAccount: chequeAccount,
        bankGroup: bankGroup,
        bankAccount: bankAccount,

      }
      Common.callApi(Common.apiIssueChequeBounce, [sid, 'saveIssueChequeBounce', JSON.stringify(obj)], (result) => {
        let resp = JSON.parse(result);
        console.log(resp.resarray);
        setScrMode('');
        setChequeList(null);
        setPartyValue(null);
        setAmount("");
        setHeaderList(resp);
        setShowHeaderList(true);
      });
    }
  }

  const btnActivityLog = () => {
    setShowActivityLog(true);
    setLogObject({ trantype: 'ISSUECHQ', trannumber: srNo, trancategory: "T" });
  }

  const handlePartyValue = (value) => {
    setPartyValue(value);
    const val = value.id.split('^');
    console.log(val);
    setGroup(val[1]);
    setAccount(val[2]);
    setAccountType(val[4]);
    // setParty(val[5]);

  }

  const handleChequeList = (value) => {
    setChequeList(value);
    const account = value.id.split('^');
    console.log(account);
    setChequeNum(account[1]);
    setMainSrNo(account[6]);
    setLineNum(account[7]);
    setMainType(account[8]);
    setChequeLocation(account[9]);
    setChequeGroup(account[10]);
    setChequeAccount(account[11]);
    let amt = account[3];
    console.log(amt);
    if (amt < 0) {
      amt = -1 * amt;
      console.log(amt);
    }
    setAmount(amt);
  }

  const handleFaView = () => {
    setShowFaView(true);
    setFaViewObject({ location: locationCode, trannumber: srNo, type: 'ISSUECHQ' });
  }

  return (
    <div>
      <Login_header />
      <Menu />
      <Container fluid>
        <Row>
          <Col>
            <h2>Issue Cheque Bounce </h2>
          </Col>
        </Row>&nbsp;
        {
          scrMode == '' ?
            <>
              <Row>
                <Col className='col-md-3 col-6'>
                  <Form.Group>
                    <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                    <AsyncSelect
                      cacheOptions={false}
                      defaultOptions={false}
                      value={selectedValue}
                      getOptionLabel={e => e.label + ' '}
                      getOptionValue={e => e.id}
                      loadOptions={Picklist.fetchLocationAllowedPicklist}
                      onInputChange={Picklist.handleInputChange}
                      onChange={(e) => handleChange(e)}
                      isDisabled={branchDisable === "true"}
                    >
                    </AsyncSelect>
                  </Form.Group>
                </Col>
                <Col className='col-md-3 col-6'>
                  <Form.Group>
                    <Form.Label>Bank<span className="text-danger">*</span></Form.Label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      value={ledgerValue}
                      getOptionLabel={e => e.label + ' '}
                      getOptionValue={e => e.id}
                      loadOptions={(search) => Picklist.fetchBankPicklist(search, 1)} // location = 1 , 2
                      onInputChange={Picklist.handleInputChange}
                      onChange={handleLedgerValue}
                    >
                    </AsyncSelect>
                  </Form.Group>
                </Col>
                <Col className='col-md-4 col-6'>
                  <Form.Group>
                    <Row style={{ marginTop: "30px" }}>
                      <Col>
                        <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" selected />
                      </Col>
                      <Col>
                        <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                      </Col>
                      <Col>
                        <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {
                  radioType === "N" ?
                    <Col className='col-md-3 col-6'>
                      <Form.Group>
                        <Form.Label>Deposit Date<span className="colorThemeRed">*</span></Form.Label><br />
                        <DatePicker className="form-control"
                          selected={filterDate}
                          onChange={(date) => setFilterDate(date)}
                          dateFormat="dd/MM/yyyy"
                          showYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                          dropdownMode="select"
                          placeholder="dd/mm/yyyy"
                          peekNextMonth
                          customInput={
                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                          }
                        />
                      </Form.Group>
                    </Col>
                    : radioType === "S" ?
                      <>
                        <Col className='col-md-3 col-6'>
                          <Form.Group>
                            <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                            <Form.Control value={srnoFrom}
                              onChange={(e) => Common.validateNumValue(e.target.value, setSrnoFrom)}
                            />
                          </Form.Group>
                        </Col>
                        <Col className='col-md-3 col-6'>
                          <Form.Group>
                            <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                            <Form.Control value={srnoTo}
                              onChange={(e) => Common.validateNumValue(e.target.value, setSrnoTo)}
                            />
                          </Form.Group>
                        </Col>
                      </>
                      : radioType === "D" ?
                        <>
                          <Col className='col-md-3 col-6'>
                            <Form.Group>
                              <Form.Label>From Date<span className="colorThemeRed">*</span></Form.Label><br />
                              <DatePicker className="form-control"
                                selected={fromDate}
                                onChange={(date) => setFromDate(date)}
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                                showMonthDropdown
                                useShortMonthInDropdown
                                dropdownMode="select"
                                peekNextMonth
                                customInput={
                                  <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col className='col-md-3 col-6'>
                            <Form.Group>
                              <Form.Label>To Date<span className="colorThemeRed">*</span></Form.Label><br />
                              <DatePicker className="form-control"
                                selected={toDate}
                                onChange={(date) => setToDate(date)}
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                                showMonthDropdown
                                useShortMonthInDropdown
                                dropdownMode="select"
                                peekNextMonth
                                customInput={
                                  <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                }
                              />
                            </Form.Group>
                          </Col>
                        </>
                        : null
                }
              </Row>&nbsp;
              <Row>
                <Col>
                  {
                    radioType == 'N' ?
                      <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleAddNew}>Add New</Button>
                      :
                      <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={handleShowList}>List</Button>
                  }
                </Col>
              </Row>&nbsp;
              {
                showHeaderList &&
                <Row>
                  <Col>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          {userRight.add === "1" ? <th></th> : null}
                          <th>Srno.</th>
                          <th>Type</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {headerList.map((res) =>
                          <tr key={res.tfd_line_no}>
                            {userRight.delete === "1" ?
                              <td className="textCenter" >
                                {
                                  <span className="handCursor"
                                    onClick={() => handleView(res.tfh_srno, res.tfh_type, res.tfh_date)}
                                  >
                                    <div className="colorThemeBlue"><FontAwesomeIcon icon={faEye} /></div>
                                  </span>
                                }
                              </td>
                              : null
                            }
                            <td>{res.tfh_srno}</td>
                            <td>{res.tfh_type}</td>
                            <td>{res.formatted_date}</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              }
            </>
            :
            <>
              <Row>
                <Col >
                  {
                    scrMode == 'A' || scrMode == 'Q' ?
                      <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={handleBack} >Back</Button>
                      :
                      <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={handleBack} >Cancel</Button>
                  }
                  &nbsp;
                  {
                    (userRight.edit === "1" && scrMode === 'E') || (userRight.add === "1" && scrMode === 'A') ?
                      <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleSaveData}>Save</Button> : null
                  }
                  &nbsp;
                  {
                    (userRight.query === "1" && (scrMode === 'E' || scrMode === 'Q')) ?
                      <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => btnActivityLog()}>Activity Log</Button> : null
                  }
                  &nbsp;
                  {
                    (userRight.query === "1" && scrMode === 'Q') ?
                      <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => handleFaView()}>View</Button> : null
                  }
                </Col>
              </Row>&nbsp;
              <Row>
                <Col className='col-md-3 col-6'>
                  <Form.Group>
                    <Form.Label>SrNo : {srNo}</Form.Label>
                  </Form.Group>
                </Col>
                <Col className='col-md-3 col-6'>
                  <Form.Group>
                    <Form.Label>Bank : {viewBank}</Form.Label>
                  </Form.Group>
                </Col>
                <Col className='col-md-3 col-6'>
                  <Form.Group>
                    <Form.Label>Location : {location}</Form.Label>
                  </Form.Group>
                </Col>
                <Col className='col-md-3 col-6'>
                  <Form.Group>
                    <Form.Label>Date : {viewDate}</Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              {
                scrMode == 'E' || scrMode == 'A' ?
                  <>
                    <Row>
                      <Col className='col-md-3 col-6'>
                        <Form.Group>
                          <Form.Label>Cheque Number<span className="text-danger">*</span></Form.Label>
                          <AsyncSelect
                            cacheOptions={false}
                            defaultOptions={false}
                            value={chequeList}
                            getOptionLabel={e => e.label + ' '}
                            getOptionValue={e => e.id}
                            loadOptions={(search) => Picklist.fetchIssueChequePicklist(search, 1, bankGroup, bankAccount)} // location = 1 , 2   
                            onInputChange={Picklist.handleInputChange}
                            onChange={handleChequeList}
                          >
                          </AsyncSelect>
                        </Form.Group>
                      </Col>
                      <Col className='col-md-3 col-6'>
                        <Form.Group>
                          <Form.Label>Party<span className="text-danger">*</span></Form.Label>
                          <AsyncSelect
                            cacheOptions={false}
                            defaultOptions={false}
                            value={partyValue}
                            getOptionLabel={e => e.label + ' '}
                            getOptionValue={e => e.id}
                            loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, ldgSrchType, 1)} // location = 1 , 2
                            onInputChange={Picklist.handleInputChange}
                            onChange={handlePartyValue}
                          >
                          </AsyncSelect>
                        </Form.Group>
                      </Col>
                      <Col className='col-md-3 col-6'>
                        <Form.Group>
                          <Form.Label>Amount<span className="text-danger">*</span></Form.Label>
                          <Form.Control
                            value={amount}
                            maxLength={Common.maximumLength}
                            onChange={(e) => Common.validateDecValue(e.target.value, setAmount)}
                            placeholder="Amount"
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row> &nbsp;
                  </> : null
              }
              {
                showList &&
                bookList.map((res) => (
                  <Row>
                    <React.Fragment key={res.tfd_line_no}>
                      <Col >
                        <Form.Group>
                          <Form.Label>Cheque No. : {res.tcd_cheque_no}</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Cheque Bank : {res.tfd_cheque_bank_name}</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Cheque Date : {res.formatted_date}</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Amount : {res.tcd_amount}</Form.Label>
                        </Form.Group>
                      </Col>
                    </React.Fragment> </Row>
                ))
              }
            </>
        }
      </Container>
      <ActivityLog logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />

      <FaView faViewObject={faViewObject} show={showFaView} onHide={() => setShowFaView(false)} />

      <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
        {msgText}
      </Dialog>
      <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
        {msgTextYN}
      </DialogYesNo>
    </div>
  );
};

export default Issue_Cheque_Bounce;